<template>
  <div>
    <div class="row">
      <div class="col-7">
        <p class="m-0 font-weight-bold">
          Total Revenue : {{ formatPrice(total, 'IDR') }}
        </p>
      </div>
      <div class="my-2 text-right col-5 right-0">
        <i class="btn btn-sm fa fa-refresh" @click="actionRefresh()"></i>
        Total {{ items ? items.length :0 }}
      </div>
    </div>    
    <b-table 
      sticky-header="500px"
      responsive="xl"
      show-empty
      :busy="isLoading"
      :fields="fields"
      hover 
      :items="items">
      <template #cell(No)="row">
        {{ row.index+1}}
      </template>
      <template #cell(period)="row">
        {{ row.item.disbursement_period ? formatdate(row.item.disbursement_period) : "" }}
      </template>
      <template #cell(offer_type)="row">
        <router-link :to="{ path: '/finance-report/'+ row.item.disbursement_period +'?offer_type=' + row.item.offer_type}">
          {{row.item ? row.item.offer_type :'' }}
        </router-link>
      </template>
      <template #cell(single)="row">
        <router-link :to="{ path: '/finance-report/'+ row.item.disbursement_period +'?offer_type=single'}">
          {{ formatPrice(row.item.single, 'IDR') }}
        </router-link>
      </template>
      <template #cell(single_diff)="row">
        <span v-if="calcDiff(items[row.index +1],row.item.single,'single') < 0" class="fa fa-arrow-down text-danger">
          {{ calcDiff(items[row.index +1],row.item.single,'single') }} %
        </span>
        <span v-else class="fa fa-arrow-up text-primary">
          {{ calcDiff(items[row.index +1],row.item.single,'single') }} %
        </span>
      </template>
      <template #cell(bundle)="row">
        <router-link :to="{ path: '/finance-report/'+ row.item.disbursement_period +'?offer_type=bundle'}">
          {{ formatPrice(row.item.bundle, 'IDR') }}
        </router-link>
      </template>
      <template #cell(bundle_diff)="row">
        <span v-if="calcDiff(items[row.index +1],row.item.bundle,'bundle') < 0" class="fa fa-arrow-down text-danger">
          {{ calcDiff(items[row.index +1],row.item.bundle,'bundle') }} %
        </span>
        <span v-else-if="calcDiff(items[row.index +1],row.item.bundle,'bundle') > 0" class="fa fa-arrow-up text-primary">
          {{ calcDiff(items[row.index +1],row.item.bundle) }} %
        </span>
      </template>
      <template #cell(subscription)="row">
        <router-link :to="{ path: '/finance-report/'+ row.item.disbursement_period +'?offer_type=subscription'}">
          {{ formatPrice(row.item.subscription, 'IDR') }}
        </router-link>
      </template>
      <template #cell(subscription_diff)="row">
        <span v-if="calcDiff(items[row.index +1],row.item.subscription,'subscription') < 0" class="fa fa-arrow-down text-danger">
          {{ calcDiff(items[row.index +1],row.item.subscription,'subscription') }} %
        </span>
        <span v-else-if="calcDiff(items[row.index +1],row.item.subscription,'subscription') > 0" class="fa fa-arrow-up text-primary">
          {{ calcDiff(items[row.index +1],row.item.subscription) }} %
        </span>
      </template>
      <template #cell(premium)="row">
        <router-link :to="{ path: '/finance-report/'+ row.item.disbursement_period +'?offer_type=buffet'}">
          {{ formatPrice(row.item.buffet, 'IDR') }}
        </router-link>
      </template>
      <template #cell(premium_diff)="row">
        <span v-if="calcDiff(items[row.index +1],row.item.buffet,'buffet') < 0" class="fa fa-arrow-down text-danger">
          {{ calcDiff(items[row.index +1],row.item.buffet,'buffet') }} %
        </span>
        <span v-else-if="calcDiff(items[row.index +1],row.item.buffet,'buffet')  > 0" class="fa fa-arrow-up text-primary">
          {{ calcDiff(items[row.index +1],row.item.buffet) }} %
        </span>
      </template>
      <template #cell(revenue)="row">
        {{ row.item ? formatPrice(row.item.total, 'IDR') :'' }}
      </template>
      <!-- <template #cell(link)="row">
         <button @click="onDownload(row.item)" class="btn btn-sm btn-primary" :disabled="isDownload">
          <b-spinner style="width: 20px; height: 20px;" class="align-middle" v-if="isDownload && row.item==current_row"></b-spinner>
          <i class="fa fa-download" v-else></i>
        </button>
      </template> -->
      <template v-slot:table-busy>
        <div class="text-center text-dark my-2">
          <b-spinner
            class="align-middle"
            variant="dark"
            type="grow"
            label="Loading .."
          >
          </b-spinner>
          Loading ..
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from "moment";
import xlsx from "sheetjs-style";
import { mapState,mapActions } from "vuex"
import PDFReports from "../../plugins/PDFReports"
  export default {
    name: 'TableReport',
    props: {
      items: {
        type: Array,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      },
    
    },
    data() {
      return {
        fields: [
          { key:'No', label: 'No'},
          {
            key: 'period',
            label: 'Period',
          },
          {
            key: 'single',
            label: 'single',
            thClass: 'text-capitalize text-right',
            tdClass: 'text-right',
          },
          {
            key:'single_diff',
            label:''
          },
          {
            key: 'bundle',
            label: 'bundle',
            thClass: 'text-capitalize text-right',
            tdClass: 'text-right',
          },
          {
            key:'bundle_diff',
            label:''
          },
          {
            key: 'subscription',
            label: 'subscription',
            thClass: 'text-capitalize text-right',
            tdClass: 'text-right',
          },
          {
            key:'subscription_diff',
            label:''
          },
          {
            key: 'premium',
            label: 'premium',
            thClass: 'text-capitalize text-right',
            tdClass: 'text-right',
          },
          {
            key:'premium_diff',
            label:''
          },
          {
            key: 'revenue',
            label: 'revenue',
            thClass: 'text-capitalize text-right',
            tdClass: 'text-right',
          },
          // {
          //   key: 'link',
          //   label: 'Dowload',
          //   thClass: 'text-center',
          //   tdClass: 'text-center',
          // }
        ]
      }
    },
    computed: {
      ...mapState({
        auth : (state) => state.auth.auth,
        isDownload: (state) => state.finance.isDownload,
      }),
      total() {
        let total = 0
        this.items.forEach((e) => {
          total += e.total;
        })
        return total;
      },
    },

    methods: {
      ...mapActions('finance',['fetchReport', 'sendReportByPeriod']),
      actionRefresh() {
        this.fetchReport()
      },
      onDownload(row) {
        this.current_row = row;
        const payload = {
          vendor_id: row.vendor_id,
          period: moment(row.disbursement_period).format("YYYY-MM-DD"),
        }
        this.sendReportByPeriod(payload).then((response) => {
          console.log(response);
          if (response?.status == 200) {
            const emailAccepted = (response.data?.data?.accepted ?? []).join(', ');
            let message = 'Report successfully sent to this email ';
            message += `
            <br>
            <small>
              (${emailAccepted})
            </small>
            `;
            this.$swal('Success Downloaded', message, 'success');
          } else {
            this.$swal('Download Failure', '', 'error');
          }
        })
        .catch(() => {
          this.$swal('opss.. please try again', '', 'error');
        })
      },
      formatPrice(value, currency) {
        if (value) {
          let formatter = null;
          if (currency == "USD") {
            formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            });
          } else {
            formatter = new Intl.NumberFormat("en-ID", {
              style: "currency",
              currency: currency,
            });
          }

          return formatter.format(value);
        }

        return 0;
      },
      sumRevenue(single,bundle,subscription,buffet) {
        return this.formatPrice(single + bundle + subscription + buffet, 'IDR')
      },
      calcDiff(next,current,type) {
        if (!next) {
          return 0
        } else if(type=='single'){
          let diff = ((next?.single - current) / next?.single) * 100
          if (diff > 0) {
            diff  = Math.abs(diff)
            return `-${diff.toFixed(2)}`
          } else {
            diff = Math.abs(diff)
            return `${diff.toFixed(2)}`
          }
        } else if (type =='bundle') {
          let diff = ((next?.bundle - current) / next?.bundle) * 100
          if (diff > 0) {
            diff  = Math.abs(diff)
            return `-${diff.toFixed(2)}`
          } else {
            diff = Math.abs(diff)
            return `${diff.toFixed(2)}`
          }
        } else if(type =='subscription') {
          let diff = ((next?.subscription - current) / next?.subscription) * 100
          if (diff > 0) {
            diff  = Math.abs(diff)
            return `-${diff.toFixed(2)}`
          } else {
            diff = Math.abs(diff)
            return `${diff.toFixed(2)}`
          }
        } else if(type =='buffet') {
          let diff = ((next?.buffet - current) / next?.buffet) * 100
          if (diff > 0) {
            diff  = Math.abs(diff)
            return `-${diff.toFixed(2)}`
          } else {
            diff = Math.abs(diff)
            return `${diff.toFixed(2)}`
          }
        }
      },
      created: function() {
        this.name = localStorage.getItem("session_name");
        let auth = localStorage.getItem("session_auth");
        if (this.name && auth) {
          this.setAuth(JSON.parse(auth));
        }
      },

      formatdate(period) {
        return moment(period).format("MMMM YYYY");
      },

      capitalizeFirstLetter(string) {
        return string != null
        ? string.charAt(0).toUpperCase() + string.slice(1)
        : '';
      },

      setTitleHead(){
        return [
          {content: 'No'},
          {content: 'Period'},
          {content: 'Sales Type'},
          {
            content: 'Revenue',
            styles: { halign: 'right' }
          }]
        
      },

      setBodyData() {
        let datatable = [];
        let n = 1;
        this.items.forEach((e) => {
          let datain = [
            {content: n++},
            {content: this.formatdate(e.disbursement_period)},
            {content: this.capitalizeFirstLetter(e.offer_type)},
            {
              content: this.formatPrice(e.total, 'IDR'),
              styles: { halign: 'right' }
            }]
          datatable.push(datain)
        });
        return datatable
      },

      setFooter() {
        let datatable = []
        let total = 0
        this.items.forEach((e) => {
          total += e.total;
        });
        let span = [{
            content: 'Total Revenue',
            colSpan: 3,
            styles: { halign: 'center', fontStyle:'bold' }
          }, {
            content: this.formatPrice(total,"IDR"),
            colSpan: 1,
            styles: { halign: 'right', fontStyle:'bold'  }
          },
        ]
        datatable.push(span)
        return datatable
      },

      actionCreateTablePDF() {
        let datetime = []
        this.items.forEach((e) => {
          datetime.push(e.disbursement_period);
        })
        let payload = {
          from: datetime[datetime.length - 1],
          to: datetime[0],
        }

        let data = this.setBodyData()
        let footer = this.setFooter()
        let datatable = data.concat(footer)
        let report = new PDFReports(
          `Revenue`,
          payload,
          '-',
          `revenue-${moment(payload.from).unix()}`,
          this.auth.username
        )
        report.SetHead(this.setTitleHead())
        report.SetData(datatable)
        report.BuildData()
        window.location.reload()
      },

      actionDownloadRecap() {
        const wb = xlsx.utils.book_new();

        let datetime = []
        this.items.forEach((e) => {
          datetime.push(e.disbursement_period);
        });
        let payload = {
          from: datetime[datetime.length - 1],
          to: datetime[0],
        };

        wb.Props = {
          Title: "Report Revenue",
          Subject: "Report",
          Author: "Digital GAM",
          CreatedDate: new Date(),
        };

        let parseData = [];
        let data = this.items;
        if (data.length < 1) {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Opss .. ",
            text: "Tidak ada data yang di rekap",
          });
        } else {
          let Attributes = [
            "No",
            "Period",
            "Sales Type",
            "Revenue",
          ];
          let wscols = [
            { wch: 8 },
            { wch: 30 },
            { wch: 20 },
            { wch: 30 },
          ];
          parseData.push(Attributes);
          let n = 1;
          data.forEach((e) => {
            let dataRevenue = [
              n++,
              this.formatdate(e.disbursement_period),
              this.capitalizeFirstLetter(e.offer_type),
              this.formatPrice(e.total, 'IDR'),
            ];
            parseData.push(dataRevenue);
          });
          const ws = xlsx.utils.aoa_to_sheet(parseData);
          ws["!cols"] = wscols;

          let total = 0
          this.items.forEach((e) => {
            total += e.total;
          });
          xlsx.utils.sheet_add_aoa(ws, [
            ["Total Revenue", '', '', this.formatPrice(total, 'IDR')]
          ], {origin:-1});
          ws["!merges"] = [
            {s: { r: data.length + 1, c: 0 },
            e: { r: data.length + 1, c: 2 }},
          ];

          let borderColor = 'ffc2cfd6';
          for (let i = 1; i < data.length + 2; i++) {
            ws['A' + i].s = {
              alignment: {horizontal: 'left'},
              border: {bottom: {style: 'thin', color: {rgb: borderColor }}}
            };
            ws['B' + i].s = {
              border: {bottom: {style: 'thin', color: {rgb: borderColor }}}
            };
            ws['C' + i].s = {
              border: {bottom: {style: 'thin', color: {rgb: borderColor }}}
            };
            ws['D' + i].s = {
              alignment: {horizontal: 'right'},
              border: {bottom: {style: 'thin', color: {rgb: borderColor }}}
            };
            if (i == 1) {
              ws['A1'].s = {
                font: {bold: true},
                alignment: {horizontal: 'left'},
                border: {
                  top: {style: 'thin', color: {rgb: borderColor }},
                  bottom: {style: 'thin', color: {rgb: borderColor }}
              }};
              ws['B1'].s = {
                font: {bold: true},
                border: {
                  top: {style: 'thin', color: {rgb: borderColor }},
                  bottom: {style: 'thin', color: {rgb: borderColor }}
              }};
              ws['C1'].s = {
                font: {bold: true},
                border: {
                  top: {style: 'thin', color: {rgb: borderColor }},
                  bottom: {style: 'thin', color: {rgb: borderColor }}
              }};
              ws['D1'].s = {
                font: {bold: true},
                alignment: {horizontal: 'right'},
                border: {
                  top: {style: 'thin', color: {rgb: borderColor }},
                  bottom: {style: 'thin', color: {rgb: borderColor }}
          }}}}

          ws['A' + (data.length + 2)].s = {
            font: {bold: true},
            alignment: {horizontal: 'center'}
          };
          ws['D' + (data.length + 2)].s = {
            font: {bold: true},
            alignment: {horizontal: 'right'}
          };

          let name = "REVENUE-" + payload.from + "-to-" + payload.to;
          xlsx.utils.book_append_sheet(wb, ws, "REPORT");
          xlsx.writeFile(wb, "REPORT_" + name + ".xlsx");
        }
      },
    }
  }
</script>

<style scoped>
  @import '../../assets/css/table.css';

  .btn-outline-success {
    color: #28a745;
    border-color: #28a745;
  }

  .btn-outline-success:hover {
    color: #fff !important;
    background-color: #28a745;
    border-color: #28a745;
  }
</style>
