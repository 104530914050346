var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-7"
  }, [_c('p', {
    staticClass: "m-0 font-weight-bold"
  }, [_vm._v(" Total Revenue : " + _vm._s(_vm.formatPrice(_vm.total, 'IDR')) + " ")])]), _c('div', {
    staticClass: "my-2 text-right col-5 right-0"
  }, [_c('i', {
    staticClass: "btn btn-sm fa fa-refresh",
    on: {
      "click": function ($event) {
        return _vm.actionRefresh();
      }
    }
  }), _vm._v(" Total " + _vm._s(_vm.items ? _vm.items.length : 0) + " ")])]), _c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "responsive": "xl",
      "show-empty": "",
      "busy": _vm.isLoading,
      "fields": _vm.fields,
      "hover": "",
      "items": _vm.items
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(period)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.disbursement_period ? _vm.formatdate(row.item.disbursement_period) : "") + " ")];
      }
    }, {
      key: "cell(offer_type)",
      fn: function (row) {
        return [_c('router-link', {
          attrs: {
            "to": {
              path: '/finance-report/' + row.item.disbursement_period + '?offer_type=' + row.item.offer_type
            }
          }
        }, [_vm._v(" " + _vm._s(row.item ? row.item.offer_type : '') + " ")])];
      }
    }, {
      key: "cell(single)",
      fn: function (row) {
        return [_c('router-link', {
          attrs: {
            "to": {
              path: '/finance-report/' + row.item.disbursement_period + '?offer_type=single'
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.single, 'IDR')) + " ")])];
      }
    }, {
      key: "cell(single_diff)",
      fn: function (row) {
        return [_vm.calcDiff(_vm.items[row.index + 1], row.item.single, 'single') < 0 ? _c('span', {
          staticClass: "fa fa-arrow-down text-danger"
        }, [_vm._v(" " + _vm._s(_vm.calcDiff(_vm.items[row.index + 1], row.item.single, 'single')) + " % ")]) : _c('span', {
          staticClass: "fa fa-arrow-up text-primary"
        }, [_vm._v(" " + _vm._s(_vm.calcDiff(_vm.items[row.index + 1], row.item.single, 'single')) + " % ")])];
      }
    }, {
      key: "cell(bundle)",
      fn: function (row) {
        return [_c('router-link', {
          attrs: {
            "to": {
              path: '/finance-report/' + row.item.disbursement_period + '?offer_type=bundle'
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.bundle, 'IDR')) + " ")])];
      }
    }, {
      key: "cell(bundle_diff)",
      fn: function (row) {
        return [_vm.calcDiff(_vm.items[row.index + 1], row.item.bundle, 'bundle') < 0 ? _c('span', {
          staticClass: "fa fa-arrow-down text-danger"
        }, [_vm._v(" " + _vm._s(_vm.calcDiff(_vm.items[row.index + 1], row.item.bundle, 'bundle')) + " % ")]) : _vm.calcDiff(_vm.items[row.index + 1], row.item.bundle, 'bundle') > 0 ? _c('span', {
          staticClass: "fa fa-arrow-up text-primary"
        }, [_vm._v(" " + _vm._s(_vm.calcDiff(_vm.items[row.index + 1], row.item.bundle)) + " % ")]) : _vm._e()];
      }
    }, {
      key: "cell(subscription)",
      fn: function (row) {
        return [_c('router-link', {
          attrs: {
            "to": {
              path: '/finance-report/' + row.item.disbursement_period + '?offer_type=subscription'
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.subscription, 'IDR')) + " ")])];
      }
    }, {
      key: "cell(subscription_diff)",
      fn: function (row) {
        return [_vm.calcDiff(_vm.items[row.index + 1], row.item.subscription, 'subscription') < 0 ? _c('span', {
          staticClass: "fa fa-arrow-down text-danger"
        }, [_vm._v(" " + _vm._s(_vm.calcDiff(_vm.items[row.index + 1], row.item.subscription, 'subscription')) + " % ")]) : _vm.calcDiff(_vm.items[row.index + 1], row.item.subscription, 'subscription') > 0 ? _c('span', {
          staticClass: "fa fa-arrow-up text-primary"
        }, [_vm._v(" " + _vm._s(_vm.calcDiff(_vm.items[row.index + 1], row.item.subscription)) + " % ")]) : _vm._e()];
      }
    }, {
      key: "cell(premium)",
      fn: function (row) {
        return [_c('router-link', {
          attrs: {
            "to": {
              path: '/finance-report/' + row.item.disbursement_period + '?offer_type=buffet'
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.buffet, 'IDR')) + " ")])];
      }
    }, {
      key: "cell(premium_diff)",
      fn: function (row) {
        return [_vm.calcDiff(_vm.items[row.index + 1], row.item.buffet, 'buffet') < 0 ? _c('span', {
          staticClass: "fa fa-arrow-down text-danger"
        }, [_vm._v(" " + _vm._s(_vm.calcDiff(_vm.items[row.index + 1], row.item.buffet, 'buffet')) + " % ")]) : _vm.calcDiff(_vm.items[row.index + 1], row.item.buffet, 'buffet') > 0 ? _c('span', {
          staticClass: "fa fa-arrow-up text-primary"
        }, [_vm._v(" " + _vm._s(_vm.calcDiff(_vm.items[row.index + 1], row.item.buffet)) + " % ")]) : _vm._e()];
      }
    }, {
      key: "cell(revenue)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item ? _vm.formatPrice(row.item.total, 'IDR') : '') + " ")];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }