<template>
  <b-row>
    <b-col lg="12" class="finance mt-2">
      <b-card class="middle">
      <h4 class="h4 text-center"> Finance Report</h4>
        <TableReport 
          :items="data"
          :busy="isLoading"
          :isLoading="isLoading"
          />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapState,mapActions} from "vuex";
import TableReport from "./table.vue"
  export default {
    name: 'FinanceReport',
    components: {
      TableReport
    },
    data() {
      return {
        data : [],
      }
    },
    computed: {
      ...mapState({
        items: (state) => state.finance.items,
        isLoading: (state) => state.finance.isLoading,
        errorMessage: (state) => state.subledger.errorMessage,
        isDownload:(state) => state.subledger.isDownload,
        successMessage: (state) => state.subledger.successMessage,
      })
    },
    mounted() {
      this.actionGet()
    },
    watch: {
      errorMessage: function() {
        if (this.errorMessage) {
          this.messageAlert('error', this.errorMessage);
        }
      },
      isDownload: function() {
        if (!this.isDownload && this.successMessage) {
          this.messageAlert('success', this.successMessage);
        }
      },
      items: function() {
        this.data = this.destructorData(this.items)
      },
      $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'Finance Report | Content Management System Ebooks Gramedia.com';
        }
      },
    },
    methods: {
      ...mapActions('finance',['fetchReport']),
      
      actionGet(){
        this.fetchReport()
        if (this.items) {
          console.log("items lenght",this.items.length)
          this.data = this.destructorData(this.items)
        }
      },
      destructorData(rows) {
        const month = [...new Set(rows.map((item) => item.disbursement_period))];
        const result = [];
        month.forEach((e) => {
          const obj = {};
          obj.disbursement_period = e;
          const findrevenue = rows.filter((row) => row.disbursement_period === e);
          if (findrevenue) {
            let single = findrevenue.find((row) => row.offer_type === "single")?.total || 0;
            let bundle = findrevenue.find((row) => row.offer_type === "bundle")?.total || 0;
            let subscription = findrevenue.find((row) => row.offer_type === "subscription")?.total || 0;
            let buffet = findrevenue.find((row) => row.offer_type === "buffet")?.total || 0;
            obj.single = single;
            obj.bundle = bundle;
            obj.subscription = subscription
            obj.buffet = buffet
            obj.total = single + bundle + subscription + buffet
          }
          result.push(obj);
        });
        /// sort by disbursement_period desc
        result.sort((a, b) => {
          return new Date(b.disbursement_period) - new Date(a.disbursement_period);
        });
        return result;
      },
      messageAlert(icon, title, timer=3000) {
        this.$swal({
            toast: "true",
            position: "top-end",
            icon,
            title,
            showConfirmButton: false,
            timer,
            timerProgressBar: true,
          });
      },
    }
  }
</script>
<style lang="css" scoped>
  .middle {
    height: 700px;
  }
  .finance {
    font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
  }
    
  .card {
    overflow: hidden;
    height: calc(100vh - 140px) !important;
  }
  
  .card.middle > .card-body > div {
    overflow: hidden;
    height: calc(100% - 40px) !important;
  }

</style>