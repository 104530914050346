var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    staticClass: "finance mt-2",
    attrs: {
      "lg": "12"
    }
  }, [_c('b-card', {
    staticClass: "middle"
  }, [_c('h4', {
    staticClass: "h4 text-center"
  }, [_vm._v(" Finance Report")]), _c('TableReport', {
    attrs: {
      "items": _vm.data,
      "busy": _vm.isLoading,
      "isLoading": _vm.isLoading
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }